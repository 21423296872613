// Import necessary libraries and definitions
import { db } from "../pages/_app";
import { collection, getDocs, query, where } from "firebase/firestore";
import { GetServerSideProps } from "next";
import Head from "next/head";

interface HandProps {
  hands: { id: string; handType: string }[];
}

// Define the component function with props typed according to the HandProps interface
export default function HandPage({ hands }: HandProps) {
  return (
    <>
      <Head>
        <title>Hand Histories</title>
        <meta name="robots" content="noindex, nofollow" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap" rel="stylesheet" />
      </Head>
      <div style={{ padding: "20px" }}>
        <div className="flex items-center"> {/* Corrected class to className */}
          <img src="images/donkey-poker1-min.png" alt="Donkey.Poker" className="w-32 h-32 mr-5 object-cover" />
          <h1>Donkey.Poker</h1>
        </div>
        <table style={{ borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  textAlign: "left",
                  backgroundColor: "#f4f4f4",
                }}
              >
                ID
              </th>
              <th style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  textAlign: "left",
                  backgroundColor: "#f4f4f4",
                }}
              >
                Type
              </th>
            </tr>
          </thead>
          <tbody>
            {hands.map((hand) => (  // Removed index as a key, use hand.id instead for unique key
              <tr key={hand.id}>
                <td style={{
                    padding: "8px",
                    border: "1px solid #ccc",
                    textAlign: "left",
                  }}
                >
                  <a href={`./hand-replayer?hand=${hand.id}`} style={{ color: 'blue', textDecoration: 'none' }}>{hand.id}</a>
                </td>
                <td style={{
                    padding: "8px",
                    border: "1px solid #ccc",
                    textAlign: "left",
                  }}
                >
                  {hand.handType}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

// getServerSideProps function to fetch data from Firestore
export const getServerSideProps: GetServerSideProps = async (context) => {
  const handsRef = collection(db, "handFiles");
  const types = ["PokerStars", "OpenHandHistory"];
  const q = query(handsRef, where("handType", "in", types));
  const querySnapshot = await getDocs(q);
  const hands = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    handType: doc.data().handType as string,
  }));

  return {
    props: {
      hands,
    },
  };
};
